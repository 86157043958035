import * as React from 'react';
import {
    SearchInput,
    Filter,
} from 'react-admin';

const InputFilter = (props) => {
    const { resource } = props;

    const resourcePlaceholderMap = {
        employees: 'Buscar por nombre',
        extraHour: 'Buscar', // TODO NO FUNCIONA EN BACKEND
        shifts: 'Buscar por descripción',
        holidays: 'Buscar', // TODO NO FUNCIONA EN BACKEND
        vehicles: 'Buscar por patente',
        inspections: 'Buscar por patente',
        orders: 'Buscar', // TODO NO FUNCIONA EN BACKEND
        tools: 'Buscar', // TODO NO FUNCIONA EN BACKEND
        items: 'Nombre o Código',
        customers: 'Buscar por cliente',
        suppliers: 'Proveedor o Nom. Fantasia',
        purchases: 'Proveedor o Nom. Fantasia',
    };

    const placeholder = resourcePlaceholderMap[resource] || 'Buscar...';

    let source;

    switch (resource) {
        case 'vehicles':
            source = 'patent';
            break;
        case 'purchases':
            source = 'supplierName';
            break;
        default:
            source = 'q';
            break;
    }


    return (
        <Filter {...props}>
            <SearchInput
                source={source}
                alwaysOn
                autoFocus
                placeholder={placeholder}
                resettable
                parse={(value) => value.toUpperCase()}
            />
        </Filter>
    );
};

export default InputFilter;
