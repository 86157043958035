import React, {
    useState,
    useEffect,
    useCallback,
} from 'react';
import {
    useDataProvider,
    usePermissions,
    useNotify,
    Loading,
} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';

import DashboardPendingOrders from './DashboardPendingOrders';
import DashboardVehiclesInWorkshop from './DashboardVehiclesInWorkshop';
import DashboardEmployees from './DashboardEmployees';
import DashboardInProgressOrders from './DashboardInProgressOrders';
//import DashboardInspection from './DashboardInspection';
import Welcome from './Welcome';
import DashboardEmployeesForgotTrack from './DashboardEmployeesForgotTrack';
import DashboardEmployeesAbsences from './DashboardEmployeesAbsences';
import DashboardChecks from './DashboardChecks';
import DashboardMyChecks from './DashboardMyChecks';
import DashboardMonthlyRevenue from './DashboardMonthlyRevenue';
import DashboardSupplier from './DashboardSupplier';
import DashboardStock from './DashboardStock';
import {
    getRolesFinance,
    getRolesOnlyDriverExceptAdmin,
    getRolesOnlyStorekeeperExceptAdmin,
    getRolesOnlySupervisorExceptAdmin,
    getRolesRRHH,
    getRolesWorkshopOnly
} from '../appRolesComponents';

const styles = {
    flex: {
        display: 'flex'
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    leftCol: {
        flex: 1,
        marginRight: '0.5em',
        marginBottom: '1em'
    },
    rightCol: {
        flex: 1,
        marginLeft: '0.5em',
        marginBottom: '1em'
    },
    singleCol: {
        marginTop: '1em',
        marginBottom: '1em'
    },
};

const Spacer = () => <span style={{ width: '1em' }} />;
const VerticalSpacer = () => <span style={{ height: '1em' }} />;

const calculateTotal = (items) => {
    return items.map(({ total }) => total).reduce((sum, i) => sum + i, 0);
}

const calculateAssets = (items) => {
    return items.map(({ assets }) => assets).reduce((sum, i) => sum + i, 0);
}

const calculateDebit = (items) => {
    return items.map(({ debit }) => debit).reduce((sum, i) => sum + i, 0);
}

const apiUrls = {
    employeeStatus: 'https://api.marasa.com.ar/reports-api/api/dashboard/employeeStatus',
    forgotTrack: 'https://api.marasa.com.ar/reports-api/api/dashboard/forgotTrack',
    absencesNow: 'https://api.marasa.com.ar/reports-api/api/dashboard/absencesNow',
    inProgressOrders: 'https://api.marasa.com.ar/reports-api/api/dashboard/inProgressOrders',
    pendingOrders: 'https://api.marasa.com.ar/reports-api/api/dashboard/pendingOrders',
    vehiclesInWorkshop: 'https://api.marasa.com.ar/reports-api/api/dashboard/vehiclesInWorkshop',
    checks: 'https://api.marasa.com.ar/finance-api/api/checks/caja',
    suppliers: 'https://api.marasa.com.ar/finance-api/api/accounts?range=0&range=1000000000&sort=id&sort=DESC',
    stockMin: 'https://api.marasa.com.ar/workshop-api/api/items/stockMin',
    stockMax: 'https://api.marasa.com.ar/workshop-api/api/items/stockMax',
};

const fetchDataWithCatch = async (url, notify) => {
    try {
        const response = await fetch(url);
        if (response.ok) {
            return await response.json();
        } else {
            const errorData = await response.json();
            throw new Error(errorData.error || 'Request failed');
        }
    } catch (error) {
        console.error(`API request failed for ${url}:`, error);
        notify(`Error al cargar los datos desde la API. Por favor, intenta nuevamente.`, 'warning');
        return null;
    }
};

const Dashboard = () => {
    const { permissions } = usePermissions();
    const notify = useNotify();
    const [state, setState] = useState({});
    const dataProvider = useDataProvider();

    const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const [loading, setLoading] = useState(true);

    const fetchDashboard = useCallback(async () => {
        setLoading(true);

        const fetchAndProcessData = async (url, processData) => {
            const data = await fetchDataWithCatch(url, notify);
            if (data) {
                processData(data);
            }
        };

        try {
            fetchAndProcessData(apiUrls.employeeStatus, employees => {
                const totalPresent = employees.totalPresent || '0';
                const workingEmployees = (employees.workingEmployees || []).filter(employee => employee.status === 'PRESENT');
                setState(state => ({ ...state, totalPresent, workingEmployees }));
            });

            fetchAndProcessData(apiUrls.forgotTrack, forgotTrack => {
                const totalForgot = forgotTrack.totalForgot || '0';
                const forgotTrackEmployees = (forgotTrack.forgotTrack || []).filter(employee => employee.status === 'FORGOT');
                setState(state => ({ ...state, totalForgot, forgotTrack: forgotTrackEmployees }));
            });

            fetchAndProcessData(apiUrls.absencesNow, holidays => {
                const totalHolidays = holidays.totalHolidays || '0';
                const holidaysEmployees = (holidays.holidays || []).filter(employee => employee.status === 'HOLIDAYS');
                setState(state => ({ ...state, totalHolidays, holidays: holidaysEmployees }));
            });

            fetchAndProcessData(apiUrls.inProgressOrders, inProgressOrders => {
                const inProgressOrdersValue = inProgressOrders || '0';
                setState(state => ({ ...state, inProgressOrders: inProgressOrdersValue }));
            });

            fetchAndProcessData(apiUrls.pendingOrders, pendingOrders => {
                const pendingOrdersValue = pendingOrders || '0';
                setState(state => ({ ...state, pendingOrders: pendingOrdersValue }));
            });

            fetchAndProcessData(apiUrls.vehiclesInWorkshop, vehiclesInWorkshop => {
                const vehiclesInWorkshopValue = vehiclesInWorkshop || '0';
                setState(state => ({ ...state, vehiclesInWorkshop: vehiclesInWorkshopValue }));
            });

            fetchAndProcessData(apiUrls.checks, checks => {
                const myChecks = (checks.data || []).filter(e => e.myCheck === true);
                const checksList = (checks.data || []).filter(e => e.myCheck === false);
                const totalMyChecks = calculateTotal(myChecks);
                const totalChecks = calculateTotal(checksList);
                setState(state => ({ ...state, totalMyChecks, totalChecks }));
            });

            fetchAndProcessData(apiUrls.suppliers, suppliers => {
                const filteredSuppliers = (suppliers.data || []).filter(e => e.primaryAccount === 'PROVEEDORES');
                const totalSuppliers = calculateDebit(filteredSuppliers) - calculateAssets(filteredSuppliers);
                setState(state => ({ ...state, totalSuppliers }));
            });

            fetchAndProcessData(apiUrls.stockMin, stockMin => {
                const totalStockMin = stockMin.total;
                const itemsStockMin = stockMin;
                setState(state => ({ ...state, totalStockMin, itemsStockMin }));
            });

            fetchAndProcessData(apiUrls.stockMax, stockMax => {
                const totalStockMax = stockMax.total;
                const itemsStockMax = stockMax;
                setState(state => ({ ...state, totalStockMax, itemsStockMax }));
            });

        } catch (error) {
            console.error('Error durante fetchDashboard:', error);
        } finally {
            setLoading(false);
        }
    }, [dataProvider, notify]); // eslint-disable-line react-hooks/exhaustive-deps



    useEffect(() => {
        fetchDashboard();
    }, [fetchDashboard]);

    const {
        workingEmployees,
        totalPresent,
        totalForgot,
        totalHolidays,
        forgotTrack,
        holidays,
        pendingOrders,
        inProgressOrders,
        vehiclesInWorkshop,
        totalMyChecks,
        totalChecks,
        totalSuppliers,
        totalStockMin,
        itemsStockMin,
        totalStockMax,
        itemsStockMax
    } = state;

    const isRRHH = getRolesRRHH(permissions);
    const isWorkshopOnly = getRolesWorkshopOnly(permissions);
    const isFinance = getRolesFinance(permissions);
    const isDriver = getRolesOnlyDriverExceptAdmin(permissions);
    const isStorekeeperAndSupervisor = getRolesOnlyStorekeeperExceptAdmin(permissions) || getRolesOnlySupervisorExceptAdmin(permissions);

    if (loading) return <Loading />;

    return isXSmall ? (
        <div>
            <div style={styles.flexColumn}>
                {(isFinance) && (
                    <div style={styles.flexColumn}>
                        <DashboardSupplier value={totalSuppliers} />
                        <VerticalSpacer />
                        <DashboardMyChecks value={totalMyChecks} />
                        <VerticalSpacer />
                        <DashboardChecks value={totalChecks} />
                        <VerticalSpacer />
                        <DashboardMonthlyRevenue value={vehiclesInWorkshop} />
                        <VerticalSpacer />
                    </div>
                )}
                {(isWorkshopOnly) && (
                    <div style={styles.flexColumn}>
                        <DashboardPendingOrders value={pendingOrders} />
                        <VerticalSpacer />
                        <DashboardInProgressOrders value={inProgressOrders} />
                        <VerticalSpacer />
                        <DashboardVehiclesInWorkshop value={vehiclesInWorkshop} />
                        <VerticalSpacer />
                    </div>
                )}

                {(isRRHH || isStorekeeperAndSupervisor || isWorkshopOnly) && (
                    <div style={styles.flexColumn}>
                        <DashboardStock
                            value={totalStockMin}
                            items={itemsStockMin}
                            typeStock={'min'}
                        />
                        <VerticalSpacer />
                        <DashboardStock
                            value={totalStockMax}
                            items={itemsStockMax}
                            typeStock={'max'}
                        />
                        <VerticalSpacer />
                        <DashboardEmployees
                            value={totalPresent}
                            workingEmployees={workingEmployees}
                        />
                        <VerticalSpacer />
                        <DashboardEmployeesForgotTrack
                            value={totalForgot}
                            forgotTrack={forgotTrack}
                        />
                        <VerticalSpacer />
                        <DashboardEmployeesAbsences
                            value={totalHolidays}
                            holidays={holidays}
                        />
                    </div>
                )}
            </div>
            {(isDriver) && (
                <Welcome />
            )}
        </div>

    ) : isSmall ? (
        <div>
            <div style={styles.flexColumn}>
                {(isFinance) && (
                    <div style={styles.singleCol}>
                        <div style={styles.flex}>
                            <DashboardSupplier value={totalSuppliers} />
                            <Spacer />
                            <DashboardMyChecks value={totalMyChecks} />
                            <Spacer />
                            <DashboardChecks value={totalChecks} />
                            <Spacer />
                            <DashboardMonthlyRevenue value={vehiclesInWorkshop} />
                        </div>
                    </div>
                )}
                {(isWorkshopOnly) && (
                    <div style={styles.flex}>
                        <DashboardPendingOrders value={pendingOrders} />
                        <Spacer />
                        <DashboardInProgressOrders value={inProgressOrders} />
                        <Spacer />
                        <DashboardVehiclesInWorkshop value={vehiclesInWorkshop} />
                    </div>
                )}

                {(isRRHH || isStorekeeperAndSupervisor || isWorkshopOnly) && (
                    <div style={styles.singleCol}>
                        <div style={styles.flex}>
                            <DashboardStock
                                value={totalStockMin}
                                items={itemsStockMin}
                                typeStock={'min'}
                            />
                            <Spacer />
                            <DashboardStock
                                value={totalStockMax}
                                items={itemsStockMax}
                                typeStock={'max'}
                            />
                            <Spacer />
                            <DashboardEmployees
                                value={totalPresent}
                                workingEmployees={workingEmployees}
                            />
                            <Spacer />
                            <DashboardEmployeesForgotTrack
                                value={totalForgot}
                                forgotTrack={forgotTrack}
                            />
                            <Spacer />
                            <DashboardEmployeesAbsences
                                value={totalHolidays}
                                holidays={holidays}
                            />
                        </div>
                    </div>
                )}
            </div>

            {(isDriver) && (
                <div style={styles.singleCol}>
                    <Welcome />
                </div>
            )}

        </div>

    ) : (

        <div>
            {(isFinance) && (
                <div style={styles.flexColumn}>
                    <div style={styles.flex}>
                        <div style={styles.rightCol}>
                            <div style={styles.flex}>
                                <DashboardSupplier value={totalSuppliers} />
                                <Spacer />
                                <DashboardMyChecks value={totalMyChecks} />
                                <Spacer />
                                <DashboardChecks value={totalChecks} />
                                <Spacer />
                                <DashboardMonthlyRevenue value={vehiclesInWorkshop} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {(isWorkshopOnly) && (
                <div style={styles.flexColumn}>
                    <div style={styles.flex}>
                        <div style={styles.rightCol}>
                            <div style={styles.flex}>
                                <DashboardPendingOrders value={pendingOrders} />
                                <Spacer />
                                <DashboardInProgressOrders value={inProgressOrders} />
                                <Spacer />
                                <DashboardVehiclesInWorkshop value={vehiclesInWorkshop} />
                            </div>
                        </div>

                    </div>
                </div>
            )}


            {(isRRHH || isStorekeeperAndSupervisor || isWorkshopOnly) && (
                <div style={styles.flexColumn}>
                    <div style={styles.flex}>
                        <div style={styles.rightCol}>
                            <div style={styles.flex}>
                                <DashboardStock
                                    value={totalStockMin}
                                    items={itemsStockMin}
                                    typeStock={'min'}
                                />
                                <Spacer />
                                <DashboardStock
                                    value={totalStockMax}
                                    items={itemsStockMax}
                                    typeStock={'max'}
                                />
                                <Spacer />
                                <DashboardEmployees
                                    value={totalPresent}
                                    workingEmployees={workingEmployees}
                                />
                                <Spacer />
                                <DashboardEmployeesForgotTrack
                                    value={totalForgot}
                                    forgotTrack={forgotTrack}
                                />
                                <Spacer />
                                <DashboardEmployeesAbsences
                                    value={totalHolidays}
                                    holidays={holidays}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {(isDriver) && (
                <div style={styles.singleCol}>
                    <Welcome />
                </div>
            )}
        </div>
    );
};

export default Dashboard;