import * as React from 'react';
import { useState } from 'react';
import {
    Create,
    TabbedForm,
    FormTab,
    useNotify,
} from 'react-admin';
import DocumentFileInput from './document/DocumentFileInput';
import AutocompleteItemsSearch from './AutocompleteItemsSearch';
import PurchaseForm from './PurchaseForm';
import PurchaseCustomToolbar from './PurchaseCustomToolbar';

const PurchaseCreate = (props) => {
    const notify = useNotify();
    const redirect = `/purchases`;
    const [loading, setLoading] = useState(true);

    const onFailure = (error) => {
        notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning');
    };

    const validate = () => {
        const errors = {};
        const purchaseCart = JSON.parse(localStorage.getItem("purchaseCart"));

        if ((purchaseCart === null || purchaseCart === undefined || (Object.keys(purchaseCart).length === 0))) {
            errors.total = ['Agregue un item'];
        }

        return errors;
    };

    const handleLoading = (isLoading) => {
        setLoading(isLoading);
    };

    return (
        <Create
            {...props}
            onFailure={onFailure}
        >
            <TabbedForm                
                toolbar={<PurchaseCustomToolbar loading={loading} />}
                redirect={redirect}
                validate={validate}
            >
                <FormTab label="General">
                    <PurchaseForm {...props} />
                </FormTab>

                <FormTab
                    label="Articulos"
                    path="items"
                >
                    <AutocompleteItemsSearch formData={undefined} handleLoading={handleLoading} />
                </FormTab>

                <FormTab
                    label="Documentación"
                    path="documents"
                >
                    <DocumentFileInput />
                </FormTab>
            </TabbedForm>
        </Create>
    );
};

export default PurchaseCreate;
