import * as React from 'react';
import { List, usePermissions, ResourceContextProvider } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import InputFilter from '../components/InputFilter';
import ConsumableListMobile from './ConsumableListMobile';
import ConsumableListDesktop from './ConsumableListDesktop';
import { getRolesOnlySupervisorExceptAdmin, getRolesWorkshopOnly } from '../appRolesComponents';

const ConsumableList = () => {
    const isXsmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const { permissions } = usePermissions();

    const isWorkshopOnly = getRolesWorkshopOnly(permissions) || getRolesOnlySupervisorExceptAdmin(permissions);
    
    if (!isWorkshopOnly) return null;

    return (
        <ResourceContextProvider value="consumables">
            <List
                syncWithLocation
                title="Consumibles"
                basePath="/consumables"
                actions={false}
                filters={<InputFilter />}
                sort={{ field: 'name', order: 'ASC' }}
                perPage={25}
                bulkActionButtons={false}
            >
                {
                    isXsmall ? (
                        <ConsumableListMobile />
                    ) : (
                        <ConsumableListDesktop />
                    )
                }
            </List>
        </ResourceContextProvider>
    );
};

export default ConsumableList;
