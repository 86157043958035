import * as React from "react";
import {
    useRecordContext
} from "react-admin";
import { Chip } from "@material-ui/core";
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

const StateChipField = ({ source }) => {
    const record = useRecordContext();
    const colors = record && record[source] === null ? red[200] : green[200];

    return (
        <Chip
            label={record && record[source] === null ? 'EN USO' : 'DEVUELTA'}
            size="small"
            style={{ backgroundColor: colors, border: 0 }}
        />
    );
};

export default StateChipField;
