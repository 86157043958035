import * as React from 'react';
import { Datagrid, NumberField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import ConsumableLinkField from './ConsumableLinkField';

const useListStyles = makeStyles({
    quantity: { color: 'purple' },
    warehouseQuantity: { color: 'green' },
});

const ConsumableListDesktop = (props) => {
    const classes = useListStyles();
    return (
        <Datagrid
            {...props}
            rowClick="edit"
            optimized
        >
            <ConsumableLinkField 
                sortable={false}
            />
            <NumberField
                label="Cant. Pañol"
                source="quantity"
                cellClassName={classes.quantity}
                sortable={false}
            />
            <NumberField
                label="Cant. Depósito"
                source="warehouseQuantity"
                cellClassName={classes.warehouseQuantity}
                sortable={false}
            />
        </Datagrid>
    );
};

export default ConsumableListDesktop;
