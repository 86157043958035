import * as React from 'react';
import { useTranslate } from 'react-admin';
import MoneyIcon from '@material-ui/icons/Money';
import CardWithIcon from './CardWithIcon';
import { currencyFormatter } from '../utils/functions';

const DashboardMyChecks = ({ value }) => {
    const translate = useTranslate();
    const total = value !== undefined && currencyFormatter(value);
    return (
        <CardWithIcon
            to="/checks"
            icon={MoneyIcon}
            title={translate('pos.dashboard.my_checks')}
            subtitle={total}
        />
    );
};

export default DashboardMyChecks;
