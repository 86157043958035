import * as React from 'react';
import { Fragment } from 'react';
import {
    SaveButton,
    Toolbar
} from 'react-admin';
import { dateParseDateTime } from '../utils/functions';
import { makeStyles } from '@material-ui/core/styles';
import StoreIcon from '@material-ui/icons/Store';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}));

const PurchaseCustomToolbar = ({
    handleSubmitWithRedirect,
    invalid,
    record,
    saving,
    loading,
}) => {
    const classes = useStyles();

    return (
        <Toolbar className={classes.root}>
            {(record.purchaseStatus === 'EN_DEPOSITO') ?
                (
                    <Fragment>
                    </Fragment>
                )
                :
                (<Fragment>
                    <SaveButton
                        handleSubmitWithRedirect={handleSubmitWithRedirect}
                        invalid={invalid}
                        saving={saving}
                        disabled={loading} // Deshabilita si loading es true
                        submitOnEnter={true}
                        transform={data => ({
                            ...data,
                            deliveryDate: null
                        })}
                    />
                    {(record && record.purchaseStatus === 'CREADA') &&
                        <SaveButton
                            label='ENVIAR A DEPOSITO'
                            handleSubmitWithRedirect={
                                () => {
                                    if (!window.confirm('¿Esta Seguro que desea enviar a Depósito?'))
                                        return false;
                                    return handleSubmitWithRedirect();
                                }
                            }
                            invalid={invalid}
                            saving={saving}
                            disabled={loading} // Deshabilita si loading es true
                            icon={<StoreIcon
                                color="primary"
                                style={{ color: 'red' }}
                            />}
                            redirect="purchases"
                            submitOnEnter={false}
                            variant="outlined"
                            transform={data => ({
                                ...data,
                                purchaseStatus: 'EN_DEPOSITO',
                                deliveryDate: dateParseDateTime(Date.now())//TODO
                            })}
                        />
                    }
                </Fragment>
                )}
        </Toolbar>
    );
};

export default PurchaseCustomToolbar;
