import React from 'react';
import { Link } from 'react-admin';

const ConsumableLinkField = props => (
    <Link to={`/consumables/${props.record.id}`}>
        {props.record.name} ({props.record.code})
    </Link>
);

ConsumableLinkField.defaultProps = {
    source: 'id',
    label: 'Consumible',
};

export default ConsumableLinkField;
