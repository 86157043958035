import * as React from 'react';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import CardWithIcon from './CardWithIcon';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const DashboardStock = ({ items = [], value, typeStock }) => {

    const urlImageBuilder = (id) => {
        return `https://api.marasa.com.ar/workshop-api/api/items/${id}/download`;
    }

    return (
        <CardWithIcon
            to="/"
            icon={typeStock === 'min' ? ArrowDownwardIcon : ArrowUpwardIcon}
            title={typeStock === 'min' ? 'Articulos menores/iguales al stock minimo' : 'Articulos mayores/iguales al stock maximo'}
            subtitle={value}
        >
            <List>
                {(typeof items.data !== 'undefined') && (items.data.length > 0)
                    ? items.data.map((record) => (
                        <ListItem
                            button
                            to={`/items/${record.id}`}
                            component={Link}
                            key={record.id}
                        >
                            <ListItemAvatar>
                                <Avatar
                                    src={urlImageBuilder(record.id)}
                                />
                            </ListItemAvatar>
                            <ListItemText
                                primary={record.name}
                                secondary={record.code}
                            />
                        </ListItem>
                    ))
                    :
                    <ListItem>
                        <ListItemText
                            primary={typeStock === 'min' ? 'No hay articulos menores al stock minimo' : 'No hay articulos mayores al stock maximo'}
                        />
                    </ListItem>
                }
            </List>
        </CardWithIcon>
    );
};

export default DashboardStock;
