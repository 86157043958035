import * as React from 'react';
import DollarIcon from '@material-ui/icons/AttachMoney';
import CardWithIcon from './CardWithIcon';
import { useTranslate } from 'react-admin';

const DashboardMonthlyRevenue = ({ value }) => {
    const translate = useTranslate();

    return (
        <CardWithIcon
            to="/cash"
            icon={DollarIcon}
            title={translate('pos.dashboard.monthly_revenue')}
            subtitle={value}
        />
    );
};

export default DashboardMonthlyRevenue;