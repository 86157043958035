import * as React from 'react';
import { Route } from 'react-router-dom';
import Accounts from './accounts/Account';
import ReportMenu from './reports-finance/ReportMenu';
import ReportMenuWorkshop from './reports-workshop/ReportMenuWorkshop';
import ReportTax from './reports-finance/ReportTax';
import ReportInvoice from './reports-finance/ReportInvoice';
import ReportRetention from './reports-finance/ReportRetention';
import ReportAccount from './reports-finance/ReportAccount';
import ReportItems from './reports-workshop/report-items/ReportItems';
import ReportVehicles from './reports-workshop/report-vehicles/ReportVehicles';

export default [
    <Route exact path="/accounts" render={() => <Accounts />} />,
    <Route exact path="/reports-finance" render={() => <ReportMenu />} />,
    <Route exact path="/reports-finance/report-tax" render={() => <ReportTax />} />,
    <Route exact path="/reports-finance/report-invoice" render={() => <ReportInvoice />} />,
    <Route exact path="/reports-finance/report-retention" render={() => <ReportRetention />} />,
    <Route exact path="/reports-finance/report-account" render={() => <ReportAccount />} />,
    <Route exact path="/reports-workshop" render={() => <ReportMenuWorkshop />} />,
    <Route exact path="/reports-workshop/report-items" render={() => <ReportItems />} />,
    <Route exact path="/reports-workshop/report-vehicles" render={() => <ReportVehicles />} />,
];
