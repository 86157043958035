import * as React from 'react';
import { useTranslate } from 'react-admin';
import CardWithIcon from './CardWithIcon';
import { currencyFormatter } from '../utils/functions';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';

const DashboardSupplier = ({ value }) => {
    const translate = useTranslate();
    const total = value !== undefined && currencyFormatter(value);
    return (
        <CardWithIcon
            to="/reports-finance/report-account"
            icon={PermContactCalendarIcon}
            title={translate('pos.dashboard.total_suppliers')}
            subtitle={total}
        />
    );
};

export default DashboardSupplier;
