import * as React from 'react';
import {
    EditButton,
} from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import CategoryField from '../components/CategoryField';

const useStyles = makeStyles(theme => ({
    root: { margin: '1em' },
    name: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardContent: {
        ...theme.typography.body1,
        display: 'flex',
        flexDirection: 'column',
    },
    margin: {
        margin: theme.spacing(1),
    },

}));

const ConsumableListMobile = ({ ids, data, basePath }) => {
    const classes = useStyles();

    const urlImageBuilder = (id) => {
        return `https://api.marasa.com.ar/workshop-api/api/items/${id}/download`;
    }

    if (!ids || !data) {
        return null;
    }
    return (
        <div className={classes.root}>
            {ids.map(id => (
                <Card key={id} className={classes.card}>
                    <CardHeader
                        title={
                            <div className={classes.cardTitleContent}>
                                <div className={classes.name}>
                                    <Avatar
                                        src={urlImageBuilder(data[id].id)}
                                    />&nbsp;&nbsp;
                                    <h6>{`${data[id].id} - ${data[id].name} (${data[id].code})`}</h6>
                                </div>
                                <EditButton
                                    resource="warehouse"
                                    basePath={basePath}
                                    record={data[id]}
                                />
                            </div>
                        }
                        subheader={
                            <div>
                                {'Categoria'}
                                :&nbsp;
                                <CategoryField id={data[id].categoryId} />
                            </div>
                        }
                    />

                    <CardContent className={classes.cardContent}>
                        <div>
                            <div>
                                {'Cant. Pañol'}
                                :&nbsp;
                                {data[id].quantity}
                            </div>
                            <div>
                                {'Cant. Depósito'}
                                :&nbsp;
                                {data[id].warehouseQuantity}
                            </div>
                        </div>
                    </CardContent>
                </Card>
            ))}
        </div>
    );
};


export default ConsumableListMobile;
