import React from 'react';
import {
    TextInput,
    NumberInput,
} from 'react-admin';
import { Box, CardContent } from '@material-ui/core';
import SectionTitle from '../components/SectionTitle';
import { requiredValidateValueWithoutZero } from '../validators/validators';

const ConsumableForm = (props) => {
    return (
        <CardContent>
            <Box display={{ md: 'block', lg: 'flex' }}>
                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                    <SectionTitle label="¿Desea finalizar el consumible?" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <NumberInput
                                autoFocus
                                label="Cantidad a Consumir"
                                source="addQuantity"
                                min="1"
                                max={props.record.warehouseQuantity}
                                defaultValue={0}
                                validate={requiredValidateValueWithoutZero}
                                fullWidth
                            />
                        </Box>
                    </Box>
                    <SectionTitle label="Stock del Pañol" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <NumberInput
                                label="Cantidad"
                                source="quantity"
                                disabled={true}
                                fullWidth
                            />
                        </Box>
                    </Box>
                </Box>

                <Box
                    flex={2}
                    ml={{ xs: 0, lg: '1em' }}
                    mt={{ xs: '1em', lg: 0 }}
                >
                    <SectionTitle label="Datos del Consumible" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="Código"
                                source="code"
                                disabled={true}
                                fullWidth
                            />
                        </Box>
                        <Box flex={2} ml={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                autoFocus
                                label="Nombre"
                                source="name"
                                disabled={true}
                                fullWidth
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </CardContent>
    );
};

export default ConsumableForm;
