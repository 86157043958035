import * as React from 'react';
import {
    Datagrid,
    TextField,
    ReferenceField,
    NumberField,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import PurchaseLinkField from './PurchaseLinkField';
import DateFieldFormatted from '../components/DateFieldFormatted';
import NumberFieldWithIVA from '../components/NumberFieldWithIVA';

const useListStyles = makeStyles({
    total: {
        textAlign: 'right',
        fontWeight: 'bold'
    },
});


const OrderListDesktop = () => {
    const classes = useListStyles();

    return (
        <Datagrid
            optimized
            rowClick="edit"
        >
            <PurchaseLinkField 
                sortable={false}
            />
            <ReferenceField
                label="Proveedor"
                source="supplierId"
                reference="suppliers"
                link={false}
                sortable={false}
            >
                <TextField source="name" />
            </ReferenceField>
             <DateFieldFormatted
                label="Fecha Inicio"
                source="date"
                sortable={false}
                showTime={false}
            />
            <DateFieldFormatted
                label="Fecha Pactada"
                source="agreedDate"
                sortable={false}
                showTime={false}
            />
            <DateFieldFormatted
                label="Fecha Entrega"
                source="deliveryDate"
                sortable={false}
                showTime={false}
            />
            <NumberField
                label="Importe Total"
                source="total"
                locales="es-AR"
                options={{ style: 'currency', currency: 'ARS' }}
                cellClassName={classes.total}
                sortable={false}
            />
            <NumberFieldWithIVA
                label="Importe Total (IVA)"
                source="total"
                locales="es-AR"
                options={{ style: 'currency', currency: 'ARS' }}
                cellClassName={classes.total}
                sortable={false}
            />
        </Datagrid>
    );
};

export default OrderListDesktop;
