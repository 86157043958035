import * as React from 'react';
import {
    Datagrid,
    NumberField,
    ChipField,
    FunctionField,
    ReferenceField,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import rowStyle from './rowStyle.js'
import PurchaseInvoiceLinkField from './PurchaseInvoiceLinkField';
import SupplierLinkField from '../suppliers/SupplierLinkField.jsx';
import DateFieldFormatted from '../components/DateFieldFormatted.jsx';
import CustomChipField from './custom-field/CustomChipField.jsx';

const useListStyles = makeStyles({
    headerRow: {
        borderLeftColor: 'white',
        borderLeftWidth: 5,
        borderLeftStyle: 'solid',
    },
    headerCell: {
        padding: '6px 8px 6px 8px',
    },
    rowCell: {
        padding: '6px 8px 6px 8px',
    },
    total: { fontWeight: 'bold' },
});

const PurchaseInvoiceListDesktop = (props) => {
    const classes = useListStyles();
    return (
        <Datagrid
            optimized
            rowStyle={rowStyle()}
            classes={{
                headerRow: classes.headerRow,
                headerCell: classes.headerCell,
                rowCell: classes.rowCell,
            }}
            {...props}
        >
            <PurchaseInvoiceLinkField
                sortable={false}
            />
            <ChipField
                label="Pago"
                size="small"
                source="invoicePaymentStatus"
                sortable={false}
            />
            <ReferenceField
                label="Proveedor"
                source="supplierId"
                reference="suppliers"
                link={false}
                sortable={false}
            >
                <SupplierLinkField />
            </ReferenceField>
            <DateFieldFormatted
                label="Fecha"
                source="date"
                sortable={false}
                showTime={false}
            />
            <FunctionField
                label="Periodo Facturado"
                render={record => (
                    <div>
                        <DateFieldFormatted record={record} source="startDate" showTime={false} />
                        {' al '}
                        <DateFieldFormatted record={record} source="endDate" showTime={false} />
                    </div>
                )}
                sortable={false}
            />
            <DateFieldFormatted
                label="Vto. de Pago"
                source="dueDate"
                sortable={false}
                showTime={false}
            />
            <NumberField
                label="Importe Total"
                source="total"
                locales="es-AR"
                options={{ style: 'currency', currency: 'ARS' }}
                cellClassName={classes.total}
                sortable={false}
            />
            <FunctionField
                label="Estado"
                render={record => <CustomChipField record={record} source="invoiceStatus" />}
            />
        </Datagrid>
    );
};

export default PurchaseInvoiceListDesktop;
