import * as React from 'react';
import {
    Edit,
    useNotify,
    useRefresh,
    useRedirect,
    SimpleForm,
} from 'react-admin';
import ToolCustomToolbar from './ToolCustomToolbar';
import ToolForm from './ToolForm';

const ToolEdit = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify('resources.employees.notification.approved_success', 'info', {}, false);
        redirect(`/tools`);
        refresh();
    };

    const onFailure = (error) => {
        notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning');
    };

    const ToolTitle = ({ record }) => {
        return <span>{record ? `SEG-${record.id}` : ''}</span>;
    };

    return (
        <Edit
            {...props}
            undoable={false}
            title={<ToolTitle />}
            onSuccess={onSuccess}
            onFailure={onFailure}
        >
            <SimpleForm
                //warnWhenUnsavedChanges
                toolbar={<ToolCustomToolbar />}
                redirect={redirect}
            >
                <ToolForm />
            </SimpleForm>
        </Edit>
    );
};

export default ToolEdit;
