import React, {
    Fragment,
} from 'react';
import {
    Create,
    TabbedForm,
    FormTab,
    useNotify,
    usePermissions,
} from 'react-admin';
import { parse } from 'query-string';
import CustomToolbar from '../components/CustomToolbar';
import DocumentFileInput from './document/DocumentFileInput';
import OrderFormItemsSearch from './OrderFormItemsSearch';
import OrderForm from './OrderForm';
import { getRolesOnlyInspectorExceptAdmin, getRolesWorkshopOnly } from '../appRolesComponents';

const OrderCreate = (props) => {
    const notify = useNotify();
    const redirect = `/orders`;
    const { permissions } = usePermissions();

    const { id: inspection_id_string } = parse(props.location.search);
    const customResource = inspection_id_string ? `/workshop-api/api/orders` : '';

    const onFailure = (error) => {
        notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning');
    };

    const isWorkshopOnly = getRolesWorkshopOnly(permissions);
    const isInspector = getRolesOnlyInspectorExceptAdmin(permissions);

    if (!isWorkshopOnly && !isInspector) return null;

    return (
        <Fragment>
            <Create
                {...props}
                title={`Crear OT`}
                resource={customResource}
                onFailure={onFailure}
            >
                <TabbedForm
                    toolbar={<CustomToolbar />}
                    redirect={redirect}
                >
                    <FormTab label="General">
                        <OrderForm {...props} />
                    </FormTab>
                    <FormTab
                        label="Pañol"
                        path="items"
                    >
                        <OrderFormItemsSearch formData={undefined} />
                    </FormTab>
                    <FormTab
                        label="Documentación"
                        path="documents"
                    >
                        <DocumentFileInput />
                    </FormTab>
                </TabbedForm>
            </Create>
        </Fragment>
    );
};

export default OrderCreate;
