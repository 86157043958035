import spanishMessages from '@blackbox-vision/ra-language-spanish';

export default {
    ...spanishMessages,
    ra: {
        action: {
            add: "Añadir",
            add_filter: "Añadir filtro",
            back: "Ir atrás",
            bulk_actions: "1 item seleccionado |||| %{smart_count} items selecccionados",
            cancel: "Cancelar",
            clear_input_value: "Limpiar valor",
            clone: "Clonar",
            close: "Cerrar",
            close_menu: "Cerrar menú",
            confirm: "Confirmar",
            create: "Crear",
            delete: "Eliminar",
            edit: "Editar",
            expand: "Expandir",
            export: "Exportar",
            list: "Listar",
            open_menu: "Abrir menú",
            refresh: "Refrescar",
            remove: "Borrar",
            remove_filter: "Borrar filtro",
            save: "Guardar",
            search: "Buscar",
            show: "Mostrar",
            sort: "Ordenar",
            undo: "Deshacer",
            unselect: "Deseleccionar"
        },
        sort: {
            sort_by: 'Ordenar por %{field} %{order}',
            ASC: 'ascendente',
            DESC: 'descendente',
        },
        auth: {
            auth_check_error: "Por favor inicie sesión para continuar",
            logout: "Cerrar Sesión",
            password: "Contraseña",
            sign_in: "Acceder",
            sign_in_error: "La autenticación falló, por favor, vuelva a intentarlo",
            user_menu: "Perfil",
            username: "Usuario"
        },
        boolean: {
            true: "Sí",
            false: "No",
            null: "",
        },
        input: {
            file: {
                upload_several:
                    "Arrastre algunos archivos para subir o haga clic para seleccionarlos.",
                upload_single:
                    "Arrastre un archivo para subir o haga clic para seleccionarlo."
            },
            image: {
                upload_several:
                    "Arrastre algunas imagénes para subir o haga clic para seleccionarlas.",
                upload_single:
                    "Arrastre alguna imagen para subir o haga clic para seleccionarla."
            },
            references: {
                all_missing: "No se pueden encontrar datos de referencias.",
                many_missing:
                    "Al menos una de las referencias asociadas parece no estar disponible.",
                single_missing: "La referencia asociada no parece estar disponible."
            },
            password: {
                toggle_visible: 'Ocultar contraseña',
                toggle_hidden: 'Mostrar contraseña',
            }
        },
        message: {
            about: "Acerca de",
            are_you_sure: "¿Está seguro?",
            bulk_delete_content:
                "¿Seguro que quiere eliminar este %{name}? |||| ¿Seguro que quiere eliminar estos %{smart_count} items?",
            bulk_delete_title:
                "Eliminar %{name} |||| Eliminar %{smart_count} %{name} items",
            delete_content: "¿Seguro que quiere eliminar este item?",
            delete_title: "Eliminar %{name} #%{id}",
            details: "Detalles",
            error:
                "Se produjo un error en el cliente y su solicitud no se pudo completar",
            invalid_form:
                "El formulario no es válido. Por favor verifique si hay errores",
            loading: "La página se está cargando, espere un momento por favor",
            no: "No",
            not_found:
                "O bien escribió una URL incorrecta o siguió un enlace incorrecto.",
            yes: "Sí",
            unsaved_changes:
                "Algunos de sus cambios no se guardaron. ¿Está seguro que quiere ignorarlos?",
        },
        navigation: {
            next: "Siguiente",
            no_more_results:
                "El número de página %{page} está fuera de los límites. Pruebe la página anterior.",
            no_results: "No se han encontrado resultados",
            page_out_from_begin: "No puede ir antes de la página 1",
            page_out_from_end: "No puede ir después de la última página",
            page_out_of_boundaries: "Número de página %{page} fuera de los límites",
            page_range_info: "%{offsetBegin}-%{offsetEnd} de %{total}",
            page_rows_per_page: "Filas por página:",
            prev: "Anterior",
            skip_nav: "Saltar al contenido"
        },
        notification: {
            bad_item: "Elemento incorrecto",
            canceled: "Acción cancelada",
            created: "Elemento creado",
            data_provider_error:
                "Error del proveedor de datos. Contacte al Administrador del sistema para más detalles.",
            deleted: "Elemento borrado |||| %{smart_count} elementos borrados.",
            http_error: "Error de comunicación con el servidor. Contacte al Administrador del sistema para más detalles.",
            item_doesnt_exist: "El elemento no existe",
            logged_out: "Su sesión ha finalizado, vuelva a conectarse.",
            updated: "Elemento actualizado |||| %{smart_count} elementos actualizados",
            i18n_error:
                "No se pudieron cargar las traducciones para el idioma especificado",
        },
        page: {
            create: "%{name}",
            dashboard: "Tablero",
            edit: "%{name} #%{id}",
            empty: "Sin %{name} todavía.",
            error: "Algo salió mal",
            invite: "¿Quiere agregar una?",
            list: "%{name}",
            loading: "Cargando",
            not_found: "No encontrado",
            show: "%{name} #%{id}"
        },
        validation: {
            email: "Debe ser un correo electrónico válido",
            maxLength: "Debe contener %{max} caracteres o menos",
            maxValue: "Debe ser %{max} o menos",
            minLength: "Debe contener %{min} caracteres al menos",
            minValue: "Debe ser al menos %{min}",
            number: "Debe ser un número",
            oneOf: "Debe ser uno de: %{options}",
            regex: "Debe coincidir con un formato específico (regexp): %{pattern}",
            required: "Requerido"
        }
    },


    pos: {
        save_and_start_ot: "Iniciar",
        save_and_end_ot: "Finalizar",
        save_and_end_ot_delivered: "Finalizar para Entregar",
        delivered: "A Facturar",
        canceled: "Cancelar",
        search: 'Buscar',
        configuration: 'Configuración',
        profile: 'Mi perfil',
        language: 'Lenguaje',
        dashboard: {
            titleChart: 'Grafico 1',
            monthly_revenue: 'Ganancia Mensual',
            total_suppliers: 'Saldo Proveedores',
            my_checks: 'Cheques Propios a Cubrir',
            others_checks: 'Cheques de Terceros a Depositar',
            in_progress_orders: 'OT en Progreso',
            pending_orders: 'OT Pendientes',
            vehiclesInWorkshop: 'Vehículos en el Taller',
            employees: 'Empleados en Planta',
            forgotTrack: 'Empleados sin fichar salida',
            absences: "Empleados con sanciones/licencias",
            order: {
                items:
                    'by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items',
            },
        },
        menu: {
            employees: 'Recursos Humanos',
            workshop: 'Taller',
            finance: 'Contabilidad',
            customers: 'Clientes',
            suppliers: 'Proveedores',
            purchasesInvoices: 'Comprobantes',
            ordersPurchases: 'Ordenes de Compra',
            receipts: 'Recibos de Cobro',
            payments: 'Ordenes de Pago',
            warehouse: 'Depósito',
            treasury: 'Tesoreria',
            accounts: 'Cuentas Corrientes',
            cash: 'Flujos de Caja',
            checks: 'Cheques'
        },
    },
    resources: {
        employees: {
            name: 'Empleados',
            fields: {
                name: 'Apellido y Nombre',
                documentNumber: 'DNI',
                email: 'Email',
                cellphone: 'Teléfono Móvil',
                shift: 'Turno',
                start_date: 'Fecha Ingreso',
                end_date: 'Fecha Egreso',
                status: 'Estado',
                role: "Puesto",
                emergencyTelephone: "Teléfono Emergencia",
                emergencyFullName: "Nombre Emergencia"
            },
            notification: {
                approved_success: 'Elemento Actualizado!',
                approved_error: 'Error: Review not approved',
                rejected_success: 'Review rejected',
                rejected_error: 'Error: Review not rejected',
            }
        },
        extra_hour: {
            name: 'Horas Extras',
            fields: {
                date: 'Fecha del Feriado'
            }
        },
        absences: {
            name: 'Ausencias',
            fields: {
                date: 'Fecha del Feriado'
            }
        },
        holidays: {
            name: 'Feriados',
            fields: {
                date: 'Fecha del Feriado'
            }
        },
        shifts: {
            name: 'Turnos',
            fields: {
                id: 'Código',
                description: 'Descripción',
            },
        },
        tracking: {
            name: 'Fichajes',
            gridList: 'Lista de Empleados',
            title: 'Lector QR para fichar Empleado',
            notification: {
                approved_success: 'Se realizo el fichaje de: %{employee} el %{date_now} ',
                approved_error: 'Error: Review not approved',
                rejected_success: 'Review rejected',
                rejected_error: 'Error: Review not rejected',
            }

        },
        reports_rrhh: {
            name: 'Reportes',
            fields: {
                name: 'Empleado',
                documentNumber: 'DNI',
                status: 'Estado',
                shift: 'Turno',
                totalHours: 'Total horas',
                first: 'Entrada',
                latest: 'Salida',
                shifts: 'Turno',
                hours: 'Total de Hs Cumplidas',
                leftHours: 'Total de Hs Faltantes',
                extraHours: 'Total de Hs Extras',
                hour: 'Cumplidas',
                leftHour: 'Faltantes',
                extraHour: 'Extras',
                totalAbsence: "Ausencias"
            },
            filters: {
                startDate: 'Fecha Desde',
                endDate: 'Fecha Hasta',
                days: 'Días de la semana',
                shifts: 'Turnos',
            },
        },
        invoices: {
            name: 'Facturas',
            fields: {
                name: 'Empleado',
                customer: 'Cliente',
                supplier: 'Proveedor',
                invoice: 'Factura',
                cuit: 'CUIT',
                documentNumber: 'DNI',
                reference: 'Referencia',
                unit_price: 'Precio Unit.',
                quantity: 'Cantidad',
                date: 'Fecha',
                start_date: 'Fecha Desde',
                end_date: 'Fecha Hasta',
                due_date: 'Vto.',
                total: 'Total',
                tax: 'Impuesto',
                tax_rate: '',
                status: 'Estado',
            },
        },
        customers: {
            name: 'Clientes',
        },
        purchases: {
            name: 'Compras',
        },
        suppliers: {
            name: 'Proveedores',
        },
        treasury: {
            name: 'Tesoreria',
        },
        reports_finance: {
            name: 'Reportes',
        },
        vehicles: {
            name: 'Vehículos',
            fields: {
                patent: 'Patente',
                brand: 'Marca',
                model: 'Modelo / Año',
                chassis: 'Carrocería',
                customer: 'Cliente',
                line: 'Linea',
                internal: 'Interno',
                color: 'Color',
                kilometres: 'Kilómetros',
            },
        },
        inspections: {
            name: 'Inspecciones',
            fields: {
                inspection: 'Inspección',
                dateInspection: 'Fecha Inspección',
                inspector: 'Inspector',
                patent: 'Patente',
                brand: 'Marca',
                model: 'Modelo / Año',
                chassis: 'Carrocería',
                customer: 'Cliente',
                line: 'Linea',
                internal: 'Interno',
                color: 'Color',
                kilometres: 'Kilómetros',
            },
        },
        orders: {
            name: 'Ordenes de Trabajo',
            notification: {
                stock_error: 'Sin stock suficiente!',
            },
            fields: {
                order: 'Orden',
                inspection: 'Inspección',
                startDate: 'Fecha Inicio',
                endDate: 'Fecha Finalización',
                deliveryDate: 'Fecha Entrega',
                workOrderStatus: 'Estado',
                total: 'Total',
                invoiced: 'Facturada',
                quantity: 'Cant.',
                itemQuantity: 'Cant. Ordenada',
                minQuantity: 'Cant. Minima',
                maxQuantity: 'Cant. Maxima',
            },
        },
        baskets: {
            name: 'Ordenes de Trabajo',
        },
        categories: {
            name: 'Categorias',
        },
        items: {
            name: 'Pañol',

        },
        tools: {
            name: 'Herramientas',
            fields: {
                employee: 'Empleado',
                tool: 'Herramienta',
                quantity: 'Cantidad',
                description: 'Descripción',
            },
        },
        reports_workshop: {
            name: 'Reportes',
        },
    },
};
