import React from 'react';
import { Link } from 'react-admin';
import { voucherFormat } from '../utils/functions';

const PurchaseInvoiceLinkField = (props) => {
    let numberVoucher = voucherFormat(props.record.id, 8);
    let pointOfSale = voucherFormat(props.record.pointOfSale, 5);
    return (
        <Link 
            to={`/purchases-invoices/${props.record.id}`} 
            onClick={() => localStorage.removeItem('purchasesInvoiceCart')}
        >
            {props.record.invoiceType} {pointOfSale}-{numberVoucher}
        </Link>
    )
};

PurchaseInvoiceLinkField.defaultProps = {
    source: 'id',
    label: 'Factura Compra Nro.',
};

export default PurchaseInvoiceLinkField;
