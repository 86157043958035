import * as React from 'react';
import { Button, Card, CardHeader, CardContent, makeStyles, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useListStyles = makeStyles(theme => ({
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    link: {
        alignItems: 'center',
    },
    cardContent: theme.typography.body1,
}));

const MenuLinkReport = ({ title, reportUrl }) => {
    const classes = useListStyles();

    return (
        <Grid item key={0} xs={12} sm={6} md={6} lg={4}>
            <Card className={classes.card}>
                <CardHeader title={title} />
                <CardContent className={classes.cardContent}>
                    <Button
                        size="small"
                        color="primary"
                        variant="outlined"
                        component={Link}
                        to={{ pathname: `/reports-workshop/${reportUrl}` }}
                        className={classes.link}
                    >
                        {'Acceder'}
                    </Button>
                </CardContent>
            </Card>
        </Grid>
    )
};
const ReportMenu = () => {
    return (
        <Grid container spacing={2}>
            <MenuLinkReport
                title={'Reporte de Articulos del Pañol'}
                reportUrl={'report-items'}
            />
            <MenuLinkReport
                title={'Reporte de Vehiculos'}
                reportUrl={'report-vehicles'}
            />
        </Grid>
    );
};

export default ReportMenu;
