import * as React from 'react';
import { Fragment } from 'react';
import {
    SaveButton,
    Toolbar
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import ThumbDown from '@material-ui/icons/ThumbDown';
import { currencyFormatter, voucherFormat } from '../utils/functions';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}));

const ReceiptEditToolbar = ({
    handleSubmitWithRedirect,
    invalid,
    record,
    saving,
}) => {
    const classes = useStyles();

    if (!record) return null;

    const total = currencyFormatter(record.total);
    const date = record.date;
    const voucher = `RECIBO ${record.pointOfSale}-${voucherFormat(record.id)}`;

    return (
        <Toolbar className={classes.root}>
            {record.status === false ? (
                <Fragment>

                </Fragment>
            ) : (
                <Fragment>
                    {(record.status === true) &&
                        <SaveButton
                            handleSubmitWithRedirect={handleSubmitWithRedirect}
                            invalid={invalid}
                            saving={saving}
                            disabled={true}
                            submitOnEnter={true}
                        />
                    }
                    {(record.status === true) &&
                        <SaveButton
                            label='Cancelar Recibo'
                            handleSubmitWithRedirect={
                                () => {
                                    if (!window.confirm(`Estas seguro que deseas cancelar el Recibo:\n${voucher}\nFecha: ${date}\nPor un total de: ${total}`))
                                        return false; //TODO---FIJARSE EL CANCEL
                                    return handleSubmitWithRedirect();
                                }
                            }
                            invalid={invalid}
                            saving={saving}
                            icon={<ThumbDown
                                color="primary"
                                style={{ color: 'red' }}
                            />}
                            redirect="receipts"
                            submitOnEnter={false}
                            variant="outlined"
                            transform={data => (
                                {
                                    ...data,
                                    status: false
                                }
                            )}
                        />
                    }
                </Fragment>
            )}
        </Toolbar>
    );
};

export default ReceiptEditToolbar;
