
import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 50,
        maxWidth: 50,
    },
}));

const ItemBonusInput = ({ product, changeBonus }) => {
    const classes = useStyles();

    return (
        <FormControl className={classes.formControl} margin="dense" size="small">
            <TextField
                id="item-Bonus"
                name="itemBonus"
                label="Bonificación"
                type="number"
                size="small"
                value={product.itemBonus}
                variant="filled"
                InputProps={{
                    inputProps: { 
                        step: "0.01",
                        min: 0, 
                        style: { textAlign: 'center' } 
                    }
                }}
                onChange={(e) => changeBonus(product, e)}
            />
        </FormControl>
    )
}

export default ItemBonusInput;