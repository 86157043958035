import * as React from 'react';
import { useState } from 'react';
import {
    Title,
    ReferenceInput,
    AutocompleteInput,
    FormDataConsumer,
} from 'react-admin';
import { withTypes } from 'react-final-form';
import { Button, Card, Box, CardContent, CardActions, CircularProgress, makeStyles } from '@material-ui/core';
import VehiclesDatagrid from './VehiclesDatagrid';
import { requiredValidate } from '../../validators/validators';
import LineSelectInput from '../../vehicles/LineSelectInput';


const useStyles = makeStyles({
    root: {
        margin: '5px',
    },
});
const { Form } = withTypes();

const ReportVehicles = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState(false);
    const [values, setValues] = useState();

    const handleSubmit = (values) => {
        if (values && Object.keys(values).length > 0) {
            console.log(values);
            setLoading(true);
            setValues(values);
            setState(true);
            setLoading(false);
        } else {
            setLoading(false);
        }
    };

    const handleButtonClick = () => {
        if (state) {
            setState(false);
            setValues(undefined);
        } else {
            handleSubmit(values);
        }
    };

    return (
        <div>
            <Form
                onSubmit={handleSubmit}
                render={({ handleSubmit, form }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <Card className={classes.root}>
                            <Title title={'Reporte Vehiculos'} />
                            <CardContent>
                                <Box display={{ md: 'block', lg: 'flex' }}>
                                    <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                        <Box display={{ xs: 'block', sm: 'flex' }}>
                                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                                <ReferenceInput
                                                    label="Busque a su Cliente"
                                                    source="customerId"
                                                    reference="customers"
                                                    sort={{ field: 'name', order: 'ASC' }}
                                                    perPage={99999} //TODO NO TRAE MAS Q 99999
                                                    fullWidth
                                                    resettable
                                                    onChange={() => {
                                                        form.change('lineId', "");
                                                    }}
                                                >
                                                    <AutocompleteInput
                                                        optionText="name"
                                                        validate={requiredValidate}
                                                    />
                                                </ReferenceInput>
                                            </Box>
                                        </Box>
                                        <Box display={{ xs: 'block', sm: 'flex' }}>
                                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                                <FormDataConsumer>
                                                    {({ formData, ...rest }) => formData.customerId !== undefined ? (
                                                        <LineSelectInput
                                                            customerId={formData.customerId}
                                                            {...rest}
                                                        />
                                                    ) : null}
                                                </FormDataConsumer>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box
                                        flex={2}
                                        ml={{ xs: 0, lg: '1em' }}
                                        mt={{ xs: '1em', lg: 0 }}
                                    >
                                        <Box display={{ xs: 'block', sm: 'flex' }}>
                                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                                <CardActions>
                                                    <Button
                                                        variant="contained"
                                                        type="submit"
                                                        color="primary"
                                                        disabled={loading}
                                                        onClick={handleButtonClick}
                                                    >
                                                        {loading && (<CircularProgress size={25} thickness={2} />)}

                                                        {state ? 'Volver a Buscar' : 'Ver Vehiculos'}
                                                    </Button>
                                                </CardActions>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                {state &&
                                    <VehiclesDatagrid values={values} />
                                }
                            </CardContent>
                        </Card>
                    </form>
                )}
            />
        </div>

    );
};

export default ReportVehicles;
